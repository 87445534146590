<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <form class="login100-form validate-form">
          <span class="login100-form-title p-b-43"> Login to continue </span>
          <div
            class="wrap-input100 validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input class="input100" type="text" name="email" />
            <span class="focus-input100"></span>
            <span class="label-input100">Email</span>
          </div>
          <div
            class="wrap-input100 validate-input"
            data-validate="Password is required"
          >
            <input class="input100" type="password" name="pass" />
            <span class="focus-input100"></span>
            <span class="label-input100">Password</span>
          </div>
          <div class="flex-sb-m w-full p-t-3 p-b-32">
            <div class="contact100-form-checkbox">
              <input
                class="input-checkbox100"
                id="ckb1"
                type="checkbox"
                name="remember-me"
              />
              <label class="label-checkbox100" for="ckb1"> Remember me </label>
            </div>
            <div>
              <a href="#" class="txt1"> Forgot Password? </a>
            </div>
          </div>
          <div class="container-login100-form-btn">
            <button class="login100-form-btn">Login</button>
          </div>
          <div class="text-center p-t-46 p-b-20">
            <span class="txt2"> or sign up using </span>
          </div>
          <div class="login100-form-social flex-c-m">
            <a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
              <i class="fa fa-facebook-f" aria-hidden="true"></i>
            </a>
            <a href="#" class="login100-form-social-item flex-c-m bg2 m-r-5">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </div>
        </form>
        <div
          class="login100-more"
          style="background-image: url('images/bg-01.jpg')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Login est requis !"),
      password: yup.string().required("Mot de passe est requis !"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.authStoreModule.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.authStoreModule.user;
    },
  },
  created() {
    if (this.loggedIn) {
      if (this.currentUser.application === "AdminApplication") {
        this.$router.push("/admin-clients");
      } else {
        this.$router.push("/client-societes");
      }
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("authStoreModule/login", user).then(
        () => {
          this.log(this.currentUser);
          if (this.currentUser.application === "AdminApplication") {
            this.$router.push("/admin-clients");
          } else {
            this.$router.push("/client-societes");
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
  margin-top: 50px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  /* margin-top: 50px; */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.logo-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  /* -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; */
}

.error-feedback {
  color: red;
}
</style>
